import React, {useState,useEffect,useCallback} from 'react';
import FadeIn from "../components/FadeIn";
import Helmet from 'react-helmet';
import Layout from '../components/Layout';
import img from '../assets/images/stéphane-plaza.jpg';


function contact() {


    return (
        <>
        <Helmet
            title="Contacter le Relais de l'avance"
            meta={[
            { name: 'description', content: "Contacter le relais de l'avance - Bar Restaurant Epicerie Traiteur situé à Saint-Étienne-le-Laus dans les Hautes Alpes 05" },
            { name: 'og:title', content: "Relais de l'avance Restaurant traiteur mariage Hautes Alpes 05"},
            { name: 'og:description', content: "Le relais de l'avance est un Bar - restaurant - traiteur pour mariage avec le concept resto'roul situé à  Saint-Étienne-le-Laus dans les Hautes Alpes 05"},
            { name: 'og:type', content: "website"}
            ]}
            >
        </Helmet>
        <Layout activeLink="contact">
            <section className="page-section about-heading">
                <div className="container">
                    
                    <div className="about-heading-content mt-3 mb-3 mb-lg-0">
                        <div className="row">
                            <div className="col-xl-9 col-lg-10 mx-auto">
                            <div className="bg-faded rounded p-5">
                                <h1 className="section-heading mb-4 section-heading-lower">
                                    Contacter le relais de l'avance
                                </h1>
                                <ul>
                                    <li>Par mail : <a href="mailto:t.lamb05@laposte.net  ">t.lamb05@laposte.net</a></li>
                                    <li>Par téléphone au 04 92 21 82 01 ou 06 31 00 44 92</li>
                                    <li>Le <a href="https://www.facebook.com/Le-relais-de-lavance-1569594323362892">facebook du relais de l'avance</a></li>
                                </ul>
                            </div>
                            </div>
                        </div>
                    </div>
                    <FadeIn loaded={true}>
                        <img
                        className="product-item-img mt-4 mx-auto d-flex rounded img-fluid mb-3 mb-lg-0 shadow2"
                        src={img}
                        alt="stephane-plaza"
                        />
                    </FadeIn>
                    <p className="mt-4 text-right text-light signature"><small><a href="https://j-rd.fr">site internet réalisé par j-rd.fr</a></small></p>
                </div>
                
            </section>
        </Layout>
        </>
    )
}

export default contact
